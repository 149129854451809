/** Togglers in header */
;(() => {
	const headerDOM = document.querySelector('.js-site-header')
	const bodyDOM = document.querySelector('body')
	const burgerDOM = document.querySelector('.js-burger-menu')
	window.menuToggler = function(){
		headerDOM.classList.toggle('is-menu-toggled')
		burgerDOM.classList.toggle('is-active')
		bodyDOM.classList.toggle('overflow-hidden')
	}
})();

;(() => {
	window.changeListItem = function(list, index){
		const listItems = document.querySelectorAll(list)
		listItems.forEach(x => x.classList.remove('is-active'))
		listItems[index].classList.add('is-active')
	}
})();

// const calcWidthDOM = document.querySelectorAll('.js-calc-width')
// if(calcWidthDOM){
// 	const recalculate = () => {
// 		calcWidthDOM.forEach(x => {
// 			const target = document.querySelector(x.getAttribute('data-target'))
// 			x.style.width = `${target.clientWidth}px`
// 		})
// 	}

// 	recalculate();
// }

window.pulse = (event, item, color) => {
	const el = document.createElement("div");
	el.classList = "absolute circle";
	if(color) el.style.backgroundColor = color;

	const rect = event.target.getBoundingClientRect()
	let cursorX = event.clientX - rect.left
	let cursorY = event.clientY - rect.top

	el.style.top = `${cursorY}px`;
	el.style.left = `${cursorX}px`;
	item.appendChild(el);
	setTimeout(() =>  el.remove(), 3000)
}