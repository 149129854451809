let possibleWrappers = [
	"#v-house-search",
	"#v-image-map",
	"#v-galactica-posts",
];

(async () => {
	if (possibleWrappers.some((x) => document.querySelector(x))) {
		
		(function(history){
			var pushState = history.pushState;
			history.pushState = function(state) {
					if (typeof history.onpushstate == "function") {
							history.onpushstate({state: state});
					}
					// ... whatever else you want to do
					// maybe call onhashchange e.handler

					console.log('history pushState')
					return pushState.apply(history, arguments);
			};
		})(window.history);
	

		const Vue = await import('Vue/dist/vue.esm-browser.prod');

		const el = document.querySelector('#v-house-search')
		if(el){
			const rfImport = await import('./partials/HouseSearch');
			rfImport.default(Vue, el);
		}

		const el2 = document.querySelector('#v-image-map')
		if(el2){
			const rfImport = await import('./partials/ImapeMap');
			rfImport.default(Vue, el2);
		}

		const galacticaPosts = document.querySelector('#v-galactica-posts')
		if(galacticaPosts){
			const rfImport = await import('./partials/GalacticaPosts');
			rfImport.default(Vue, galacticaPosts);
		}

		// const galacticaTiles = document.querySelector('#v-galactica-tiles')
		// if(galacticaTiles){
		// 	const rfImport = await import('./partials/GalacticaPosts');
		// 	rfImport.default(Vue, galacticaTiles);
		// }
	}
})();



